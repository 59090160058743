<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

import {
  required,
} from "vuelidate/lib/validators";


/**
 * System Settings component
 */
export default {
  page: {
    title: "Configurações do Sistema",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Configurações do Sistema",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Configurações do Sistema",
          active: true,
        },
      ],    
      times: ['1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x', '11x', '12x'],
      form: {
        name: "",
        title: "",
        keyWords: "",
        description: "",
        author: "",
        cnpj: "",
        slogan: "",
        email: "",
        address: "",
        phone: "",
        whatsapp: "",
        ipAddress: "",
        times: "1x",
        footerText: "",
        footerLink: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      name: { required },
      title: { required },
      slogan: { required },
      email: { required },
      times: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row">      
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Configurações do Sistema</h4>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Nome do Site <span class="required">*</span></label>                    
                    <input
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="Insira o nome do site"
                      name="name"
                      :class="{
                        'is-invalid':
                          typesubmit && $v.form.name.$error,
                      }"
                    />
                    <div
                      v-if="typesubmit && $v.form.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.name.required"
                        >Este campo é obrigatorio.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Título do Site <span class="required">*</span></label>                    
                    <input
                      v-model="form.title"
                      type="text"
                      class="form-control"
                      placeholder="Insira o título do site"
                      name="title"
                      :class="{
                        'is-invalid':
                          typesubmit && $v.form.title.$error,
                      }"
                    />
                    <div
                      v-if="typesubmit && $v.form.title.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.title.required"
                        >Este campo é obrigatorio.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="website_keywords">Palavras-chave do site</label>
                    <input
                      v-model="form.keyWords"
                      data-role="tagsinput" 
                      type="text"
                      class="form-control"
                      placeholder="Dígite as palavras chaves do site"
                      name="key-words"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-12">
                  <label class="col-md-3 form-label"
                  >Descrição do Site</label
                  >
                  <textarea
                    v-model="form.description"
                    rows="7"
                    class="form-control"
                    name="description"
                    placeholder="Descrição do site"
                  ></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Autor</label>                    
                    <input
                      v-model="form.author"
                      type="text"
                      class="form-control"
                      placeholder="Insira o nome do autor"
                      name="author"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label" for="validationCustom01">CNPJ</label>                    
                    <input
                      v-model="form.cnpj"
                      type="text"
                      v-mask="'##.###.###/####-##'"
                      class="form-control"
                      placeholder="Insira o CNPJ"
                      name="cnpj"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Slogan <span class="required">*</span></label>                    
                    <input
                      v-model="form.slogan"
                      type="text"
                      class="form-control"
                      placeholder="Insira o nome do autor"
                      name="slogan"
                      :class="{
                        'is-invalid':
                          typesubmit && $v.form.slogan.$error,
                      }"
                    />
                    <div
                      v-if="typesubmit && $v.form.slogan.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.slogan.required"
                        >Este campo é obrigatorio.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">E-mail <span class="required">*</span></label>                    
                    <input
                      v-model="form.email"
                      type="email"
                      class="form-control"
                      placeholder="Insira o e-mail"
                      name="email"
                      :class="{
                        'is-invalid':
                          typesubmit && $v.form.email.$error,
                      }"
                    />
                    <div
                      v-if="typesubmit && $v.form.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.email.required"
                        >Este campo é obrigatorio.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-12">
                  <label class="col-md-3 form-label"
                  >Endereço</label
                  >
                  <textarea
                    v-model="form.address"
                    rows="7"
                    class="form-control"
                    name="address"
                    placeholder="Digite o endereço"
                  ></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Telefone</label>                    
                    <input
                      v-model="form.phone"
                      type="text"
                      v-mask="'(##) ####-####'"
                      class="form-control"
                      placeholder="Insira o telefone"
                      name="phone"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Whatsapp</label>                    
                    <input
                      v-model="form.whatsapp"
                      type="text"
                      v-mask="'(##) #####-####'"
                      class="form-control"
                      placeholder="Insira o whatsapp"
                      name="whatsapp"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Endereço IP</label>                    
                    <input
                      v-model="form.ipAddress"
                      type="text"
                      class="form-control"
                      placeholder="Insira o endereço de ip"
                      name="ip-address"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label class="form-label"
                  >Parcelamento de Cursos: 1x à 12x <span class="required">*</span></label
                >
                <multiselect 
                  v-model="form.times" 
                  :options="times" 
                  placeholder="Selecione a quantidade de vezes de parcelamento"
                  :class="{
                    'is-invalid':
                      typesubmit && $v.form.times.$error,
                  }"
                ></multiselect>
                <div
                  v-if="typesubmit && $v.form.times.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.times.required"
                    >Este campo é obrigatorio.</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Texto do Rodapé</label>                    
                    <input
                      v-model="form.footerText"
                      type="text"
                      class="form-control"
                      placeholder="Insira o texto do rodapé"
                      name="footer-text"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Link do Rodapé</label>                    
                    <input
                      v-model="form.footerLink"
                      type="text"
                      class="form-control"
                      placeholder="Insira o link do rodapé"
                      name="footer-link"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-3">
                  <button class="btn btn-primary w-100" type="submit">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
